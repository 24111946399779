<template>
    <div class="user-profile">
        <div class="box">
            <h2>ตั้งค่าความเป็นส่วนตัว</h2>

            <div class="loading" v-if="!isContentReady">
                <loader-content :isDisplay="true"></loader-content>
            </div>

            <div class="form profile" v-if="isContentReady">
                <label class="label-privacy">กดยอมรับเพื่อให้ Yellowtire ส่งหรือเปิดเผยข้อมูลส่วนบบุคคลของท่านให้แก่บริษัทในเครือเพื่อวัตถุประสงค์ทางการตลาด และ การติดต่อสื่อสาร</label>
                <div class="accept-box">
                    <checkbox
                        @change="savePrivacy()"
                        v-model="isAcceptPrivacy">
                        ยอมรับ
                    </checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserService from '@/services/userService';

export default {
    data() {
        return {
            isContentReady: false,
            profile: null,
            isAcceptPrivacy: false
        };
    },
    mounted() {
        this.loadPrivacy();
    },
    methods: {
        async loadPrivacy() {
            this.isContentReady = false;

            const result = await UserService.getPrivacyProfile();

            this.profile = result.data;
            this.isAcceptPrivacy = this.profile.isAcceptPrivacy;
            this.isContentReady = true;
        },
        async savePrivacy() {
            let loader = this.$modalLoader.render();
            await UserService.savePrivacy(this.isAcceptPrivacy);

            loader.hide();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/ProfileSetting.scss';

.loading {
    height: 400px;
}

.label-privacy {
    font-size: 13px;
    margin-bottom: 14px;
}

.accept-box {
    padding-left: 20px;
}
</style>